import React, { FC, ReactNode } from 'react'
import { HeadFC, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import LayoutDefault from '@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-default'
import PhoneButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/popup-button";
import '../styles/variables.scss'

export type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      company: companyJson {
        name
        slogan
        address {
          cidade
        }
      }
      logoHorizontal: file(name: { eq: "logo-horizontal" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const company = data.company
  const logoHorizontalImage = getImage(data.logoHorizontal)!

  const getGatsbyImageNode = (
    image: IGatsbyImageData,
    alt: string,
    isContained?: boolean
  ) => (
    <GatsbyImage
      image={image}
      alt={alt}
      loading="eager"
      style={{ width: '100%', height: '100%' }}
      objectFit={isContained ? 'contain' : 'cover'}
    />
  )

  return (
    <LayoutDefault
      logoFooter={getGatsbyImageNode(
        logoHorizontalImage,
        'Logo Ca and Ma',
        true
      )}
      logoTopbar={getGatsbyImageNode(
        logoHorizontalImage,
        'Logo Ca and Ma',
        true
      )}
      companyName={company.name}
      city={company.address.cidade}
      slogan={company.slogan}
      badge={{ type: 'digital' }}
      navItems={[
        {
          label: 'Home',
          path: '/'
        },
        {
          label: 'Produtos',
          path: '/produtos'
        },
        {
          label: 'Contato',
          path: '/contato'
        }
      ]}
      facebook="CaAndMaComponentesEletronicos"
      instagram="caandmacomp/"
      linkedin=""
    >
      {children}
    </LayoutDefault>
  )
}

export default Layout

export const Head: HeadFC<{}> = ({ }) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
)
